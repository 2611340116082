import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, trainees, material) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
              body: [
                [
                  { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: data.circular_memo_no, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' }
                ],
                [
                  { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' },
                  { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
                  { text: ':', style: 'td', alignment: 'center' },
                  { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' }
                ]
              ]
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: vm.$t('globalTrans.details'), style: 'hh', alignment: 'center' })
        // option
      const allRows = []
      const firstData = []
      const width = ['5%', '9%', '8%', '9%', '9%']
      firstData.push(
        { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('elearning_iabm.registration_for'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('globalTrans.name') + '\n' + vm.$t('admission_form.mobile_no'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center', rowSpan: 2 }
      )
      const widthCheck = 28 / material.length
      material.filter((mat, matIndex) => {
        if (matIndex === 0) {
          firstData.push(
            { text: vm.$t('elearning_tim.materials'), style: 'th', alignment: 'center', colSpan: material.length }
          )
        } else {
          firstData.push(
            {}
          )
        }
        width.push((widthCheck) + '%')
      })
      width.push('10%', '7%', '10%', '8%')
      firstData.push(
        { text: vm.$t('globalTrans.honour_amount'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('globalTrans.payment_amount'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('globalTrans.date_and_signature'), style: 'th', alignment: 'center', rowSpan: 2 },
        { text: vm.$t('elearning_tim.comment'), style: 'th', alignment: 'center', rowSpan: 2 }
      )
      allRows.push(firstData)

      const newData = [
        {},
        {},
        {},
        {},
        {}
      ]
      material.filter((mat, matIndex) => {
        newData.push(
          { text: vm.$i18n.locale === 'bn' ? mat.name_bn : mat.name, style: 'th', alignment: 'center' }
        )
      })
      newData.push(
        {},
        {},
        {}
      )
      allRows.push(newData)
      trainees.filter((data, optionIndex) => {
        const rowItem = [
          { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.user_type_name_bn : data.user_type_name, style: 'td', alignment: 'center' },
          { text: (vm.$i18n.locale === 'bn' ? data.personal.name_bn : data.personal.name) + '\n' + ((vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.mobile, { useGrouping: false })), style: 'td', alignment: 'center' },
          { text: data.personal.designation, style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'center' }
        ]
        material.filter((mat, matIndex) => {
          rowItem.push(
            { text: vm.$n(mat.quantity), style: 'th', alignment: 'center' }
          )
        })
        rowItem.push(
          { text: vm.$n(data.honour_amount), style: 'td', alignment: 'center' },
          { text: vm.$n(data.payment_amount), style: 'td', alignment: 'center' },
          { text: '', style: 'td', alignment: 'center' },
          { text: data.comments, style: 'td', alignment: 'center' }
        )
        allRows.push(rowItem)
      })
      pdfContent.push({ text: '', style: 'tableMargin' })
      pdfContent.push({
        table: {
          headerRows: 2,
          widths: width,
          body: allRows
        }
      })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
      styles: {
        th: {
          fontSize: (i18n === 'bn') ? 11 : 9,
          margin: [3, 3, 3, 3]
        },
        td: {
          fontSize: (i18n === 'bn') ? 11 : 9,
          margin: [3, 3, 3, 3]
        },
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 0]
      },
      org: {
          fontSize: 13,
          bold: true,
          margin: [0, -25, 10, 5]
      },
      address: {
          fontSize: 11,
          margin: [0, 0, 0, 5]
      },
      hh: {
          fontSize: 13,
          bold: true,
          margin: [10, 10, 25, 20]
      },
        header2: {
          fontSize: 14,
          margin: [0, 10, 0, 20]
        },
        fertilizer: {
          fontSize: 10,
          margin: [10, 10, 0, 20]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 0]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 15]
        },
        krishi: {
          margin: [0, -5, 0, 15],
          alignment: 'center'
        },
        header3: {
          fontSize: 9,
          margin: [0, 0, 0, 4]
        },
        tableSubHead: {
          margin: [0, 5, 0, 15]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
